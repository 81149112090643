import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { noop } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import {
  DateRangePicker,
  Dropdown,
  Icons,
  InputWithSuffixIcon,
} from '@shopline/dashboard-ui';
import { LanguageName } from '@shopline/dashboard-ui/types/themes/fonts';
import styled from 'styled-components';
import { getTranslation } from 'utils/translate';
import useQueryChannels, { Channels } from 'hooks/useQueryChannels';
import { All } from 'features/PurchaseOrderBulkImport/constants';
import E2E_PREFIX from './e2ePrefix';

const FilterWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export interface IProps {
  setQuery: Dispatch<SetStateAction<string>>;
  setRanges: Dispatch<
    SetStateAction<{ startDate: Date | undefined; endDate: Date | undefined }>
  >;
  setChannelId: Dispatch<SetStateAction<string>>;
  setPage: Dispatch<SetStateAction<number>>;
  channelId: string;
  isDisabled: boolean;
}

const SearchBar = ({
  setQuery,
  setRanges,
  setChannelId,
  setPage,
  channelId,
  isDisabled,
}: IProps) => {
  const { t, i18n } = useTranslation(['purchaseOrderBulkImport']);
  const [channelOpen, setChannelOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { language } = i18n as { language: LanguageName };
  const handleChannelChange = (optionValues: string[]) => {
    const channelId = optionValues[0];
    setChannelId(channelId);
    setChannelOpen(false);
    setPage(1);
  };
  const transToDropdownOptions = (data: Channels) => {
    const allOption = {
      key: All,
      value: '',
      label: t('All Stores'),
    };
    const options = data.items.map(({ id, name_translations }) => ({
      key: id,
      value: id,
      label: getTranslation(name_translations, language),
    }));
    return [allOption, ...options];
  };
  const { data: channels } = useQueryChannels(transToDropdownOptions);
  const handleQueryClick = () => {
    setQuery(inputRef?.current?.value || '');
    setPage(1);
  };
  return (
    <FilterWrapper>
      <InputWithSuffixIcon
        data-e2e-id={`${E2E_PREFIX}-purchase-order-number-filter`}
        ref={inputRef}
        status="valid"
        placeholder={t('Search Purchase Order Number')}
        Icon={Icons.Outline.Search}
        onButtonClick={handleQueryClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleQueryClick();
          }
        }}
        disabled={isDisabled}
      />
      <DateRangePicker
        data-e2e-id={`${E2E_PREFIX}-date-range-picker-filter`}
        disabled={isDisabled}
        lang={language}
        onRangeChange={({ range }) => {
          const [startDate, endDate] = range || [];
          if ((startDate && endDate) || (!startDate && !endDate)) {
            setRanges({ startDate, endDate });
            setPage(1);
          }
        }}
      />
      <Dropdown
        e2eId={`${E2E_PREFIX}-channel-filter`}
        disabled={isDisabled}
        width="9.375rem"
        minHeight="2.375rem"
        useDefaultBorder
        disabledShadow
        isOpen={channelOpen}
        onClose={() => setChannelOpen(false)}
        placeHolder={t('All Stores')}
        options={channels || []}
        optionMaxHight="15rem"
        onChange={handleChannelChange}
        onClick={isDisabled ? noop : () => setChannelOpen((prev) => !prev)}
        value={[channelId]}
        menuAlign="left"
      />
    </FilterWrapper>
  );
};

export default SearchBar;
