import { useTranslation } from 'react-i18next';
import useExportReference, { ExportReference } from './useExportReference';
import { All } from 'features/PurchaseOrderBulkImport/constants';

const useExportPurchaseOrderReference = ({
  selectedCategory,
  selectedSupplier,
}: {
  selectedCategory: string;
  selectedSupplier: string;
}) => {
  const { t } = useTranslation(['purchaseOrderBulkImport']);
  const payload: ExportReference = {
    job: {
      title_translation: {
        'zh-hant': t('Export purchase order ID reference file', {
          lng: 'zh-hant',
        }),
        'zh-cn': t('Export purchase order ID reference file', { lng: 'zh-cn' }),
        en: t('Export purchase order ID reference file', { lng: 'en' }),
      },
      type: 'purchase_order',
      total_count: 1,
    },
    data: [
      { kind: 'channel', fields: ['id', 'name'] },
      { kind: 'agent', fields: ['id', 'name'] },
      {
        kind: 'product',
        filters: [
          { key: 'supplier_id', values: [selectedSupplier] },
          { key: 'category_id', values: [selectedCategory] },
        ].filter(({ values }) => values[0] !== All),
        fields: [
          'id',
          'name',
          'variation_id',
          'variation_name',
          'sku',
          'cost',
          'supplier',
        ],
      },
    ],
  };
  return useExportReference(payload);
};

export default useExportPurchaseOrderReference;
