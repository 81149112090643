import { OptionProps } from '@shopline/dashboard-ui/types/components/dropdown/Dropdown';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

export interface Channels {
  items: {
    id: string;
    name_translations: { [lang: string]: string };
    name: string;
    updated_at: string;
    created_at: string;
  }[];
}

const getChannels = async (merchantId: string): Promise<Channels> => {
  const { data } = await axios.get<Channels>(
    `/api/merchants/${merchantId}/channels`,
  );
  return data;
};

const useQueryChannels = (select?: (items: Channels) => OptionProps[]) => {
  const { merchantId } = useParams<{ merchantId: string }>();
  return useQuery<Channels, Error, OptionProps[]>(
    'channels',
    () => getChannels(merchantId),
    {
      select,
      enabled: !!merchantId,
    },
  );
};

export default useQueryChannels;
